import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from "react-router-dom";

import LandingPage from "./modules/views/LandingPage/LandingPage";
import Verify from "./modules/views/verify/Verify";

function App() {
  const { userid } = useParams();
  console.log(userid);
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LandingPage />} />
        <Route path="/verify" element={<Verify />} />
      </Routes>
    </Router>
  );
}
function GetID() {
  // Get the userId param from the URL.
  let { userId } = useParams();
}
function Index() {
  return (
    <React.Fragment>
      <LandingPage />
    </React.Fragment>
  );
}

export default App;
